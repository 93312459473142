<template>
  <div class="pages">
    <!--顶部头像-->
    <div class="headerInfo">
      <img class="headBack" src="../static/images/main/banner.png" width="100%"/>
<!--      <div class="userInfo">-->
<!--        <img :src="user.Head" class="round_icon">-->
<!--        <span>{{ user.Name }}</span>-->
<!--      </div>-->
    </div>
    <div class="info" v-show="false">
      <ul>
        <li>
          <em>{{ user.Height }}</em>
          <span>身高cm</span>
        </li>
        <li>
          <em>{{ user.Age }}</em>
          <span>年龄</span>
        </li>
        <li>
          <em>{{ user.Weight }}</em>
          <span>体重kg</span>
        </li>
        <li>
          <em>{{ user.Sex }}</em>
          <span>性别</span>
        </li>
      </ul>
    </div>

    <!--以下是控制隐藏项-->
    <!--全部课程-->
    <div class="pageClass">
      <em>全部课程</em>
      <ul class="dayList">
        <li class="dayDetail" v-for="classItem in classRs" @click="showLessonDetail(classItem)">
            <span class="dayTitle">  {{ classItem.BeginTime | filterTime }}  {{
                classItem.BeginTime | filterWeek
              }} </span>
          <span class="className" :style="{ color: classItem.ClassColor,border : '1px solid '+ classItem.ClassColor}">{{
              classItem.ClassName
            }}</span>
          <div class="sportDetail">
            <ul>
              <li>
                <em>
                  <img class="fa-icon" src="../static/images/report/report_cle_total.png"/>
                  {{ classItem.Cle }}
                </em>
                <span>卡路里</span>
              </li>
              <li>
                <em>
                  <img class="fa-icon" src="../static/images/report/report_ei.png"/>
                  {{ classItem.Ei }}
                </em>
                <span>运动强度</span>
              </li>
              <li>
                <em>
                  <img class="fa-icon" src="../static/images/report/report_ck.png"/>
                  {{ classItem.Ck }}
                </em>
                <span>CK</span>
              </li>
            </ul>
            <div class="sportDuration">
              <span>运动时长</span>
              <em>{{ classItem.SportDuration }}</em>
              <span>分钟</span>
            </div>
            <div class="sportView" v-show="false">
              <span>详细信息</span>
              <em>&gt;</em>
            </div>
          </div>
        </li>
      </ul>

      <em @click="loadMore">{{ isLoading }}</em>
    </div>
    <!--底部课程统计-->
    <bottomTab :curTab="thisTab"/>
  </div>
</template>

<script>
import axios from 'axios';
import bottomTab from '../components/bottomTab'
import {
  VipUserSelfQuery,
  PersonClassList,
} from '../api/getApiRes.js'

let qs = require('qs');
import Global from '../Global.js'
// 引入基本模板
let echarts = require('echarts/lib/echarts')
// 引入柱状图组件
require('echarts/lib/chart/bar')
// 引入提示框和title组件
require('echarts/lib/component/tooltip')
require('echarts/lib/component/title')

export default {
  data() {
    return {
      thisTab: '上课统计',
      testColor2: '#F2F2F2',
      testColor: '#FFE58D',
      screenWidth: document.body.clientWidth + 'px',
      //控制页面开合
      visiblePageClass: false,//全部课程显示
      scrollTop: 190,
      user: {
        Name: '',
        Age: '',
        Height: '',
        Weight: '',
        Phone: '',
        Sex: '',
        Head: '',
        CKToTal: 0,
        CKMonth: 0,
        CalorieMonth: 0,
        CalorieTotal: 0,
      },
      classRs: [],
      busy: true,
      start: 1,
      pageItem: 5,
      tableMax: 5,
      isLoading: '点此加载更多'
    }
  },
  watch: {
    '$route'(to) {
      if (to.name == 'reportLesson') {

        this.getUserInfo();//获取用户基本信息
        this.getClassReport();//获取全部课程信息
      }
    },
  },
  mounted() {
    let that = this;
    that.getUserInfo();//获取用户基本信息
    that.getClassReport();//获取全部课程信息
  },
  methods: {
    //获取用户基本信息
    getUserInfo() {
      let that = this;
      let param = {
        token: localStorage.token
      };
      let postData = qs.stringify(param);
      VipUserSelfQuery(postData).then(res => {
        let json = res;
        if (json.Code == 0) {
          that.user = json.Rs;
        } else {
          that.$toast.message(json.Memo);
        }
      })
    },
    //获取课程统计
    getClassReport() {
      let that = this;
      let param = {
        token: localStorage.token,
        start: 0,
        tableMax: this.tableMax
      };
      let postData = qs.stringify(param);
      PersonClassList(postData).then(res => {
        let json = res;
        if (json.Code == 0) {
          that.classRs = json.Rs;
          if (that.classRs.length < that.tableMax) {
            that.isLoading = '没有更多了';
          }
        } else {
          that.$toast.message(json.Memo);
        }
      })
    },
    loadMore() {
      if (this.isLoading === '没有更多了') {
        return false;
      }
      this.isLoading = '加载中...';
      this.start = this.start + 1;
      this.tableMax = this.start * this.pageItem;
      setTimeout(() => {
        this.getClassReport();
        this.isLoading = '点此加载更多';
      }, 1000);
    },
    showLessonDetail(classItem) {
      this.$router.push({path: '/reportLessonDetail', query: {DuId: classItem.DuId, StdId: classItem.StdId}});
    }
  },
  filters: {
    hidePhone: function (val) {
      let str = String(val)
      let len = str.length;
      if (len >= 7) {
        let reg = str.slice(-8, -4)
        return str.replace(reg, "****")
      } else if (len < 7 && len >= 6) {
        let reg = str.slice(-4, -2)
        return str.replace(reg, "**")
      }
    },
    fmtTime: function (value) {
      if (!value) {
        return 0
      } else {
        // js默认使用毫秒
        value = value * 1000
        let date = new Date(value);
        let y = date.getFullYear();
        let MM = date.getMonth() + 1;
        MM = MM < 10 ? ('0' + MM) : MM;
        let d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        let h = date.getHours();
        h = h < 10 ? ('0' + h) : h;
        let m = date.getMinutes();
        m = m < 10 ? ('0' + m) : m;
        let s = date.getSeconds();
        s = s < 10 ? ('0' + s) : s;
        return y + '-' + MM + '-' + d;
      }
    },
    filterTime: function (value) {
      if (!value) {
        return 0;
      } else {
        return dateFormatWithoutZone(value, 16);
      }
    },
    filterWeek: function (value) {
      if (!value) return false;
      let days = new Date(value).getDay();
      switch (parseInt(days)) {
        case 1:
          return "星期一";
          break;
        case 2:
          return "星期二";
          break;
        case 3:
          return "星期三";
          break;
        case 4:
          return "星期四";
          break;
        case 5:
          return "星期五";
          break;
        case 6:
          return "星期六";
          break;
        case 0:
          return "星期天";
          break;
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      //因为当钩子执行前，组件实例还没被创建
      // vm 就是当前组件的实例相当于上面的 this，所以在 next 方法里你就可以把 vm 当 this 来用了。
      if (to.name == 'reportLesson') {
        vm.getUserInfo();
      }
    });
  },
  components: {
    bottomTab
  }
}
</script>

<style scoped>
.pages {
  overflow: hidden;
  display: block;
  margin: 0 auto;
  background: #F2F2F2;
}

.headerInfo {
  position: relative;
}

.headerInfo .headBack {
  height: 120px;
  position: absolute;
  z-index: 1;
}

.headerInfo .userInfo {
  left: 42%;
  top: 1.25rem;
  position: absolute;
  z-index: 2;
  text-align: center;
}

.headerInfo .userInfo span {
  font-size: 16px;
  color: white;
  display: block;
  margin-top: 5px;
}

.round_icon {
  width: 64px;
  height: 64px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 3px;
  border: solid 2px white;
}

.info {
  background: #fff;
  width: 100%;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  margin-top: 120px;
}

.info ul {
  width: 100%;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.info li {
  width: 25%;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  float: left;
  text-align: center;
}

.info li:nth-child(2) {
  border-color: #e2e2e2;
}

.info li:nth-child(3) {
  border-color: #e2e2e2;
  border-left: 0;
}

.info li em {
  width: 100%;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  color: #363636;
  font-size: 20px;
  text-align: center;
}

.info li span {
  width: 100%;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  color: #c4c4c4;
  font-size: 12px;
  text-align: center;
}

.tag {
  height: 252px;
}

.tag .card {
  width: 45%;
  float: left;
  height: 106px;
  margin-left: 3%;
  margin-top: 20px;
  border-radius: 13px;
  background: linear-gradient(#FFFFFF, #FFD4D3);
}

.tag .card .row1 img {
  margin-left: 10px;
  margin-top: 10px;
  width: 17px;
  height: 18px;
  float: left;
}

.tag .card .row1 em {
  font-family: "PingFang SC";
  font-size: 12px;
  line-height: 40px;
  margin-left: 7px;
  color: #3b3b3b;
}

.tag .card .row2 span {
  font-family: "PingFang SC";
  font-size: 22px;
  font-weight: bold;
  margin-left: 35px;
  color: #363636;
}

.tag .card .row2 em {
  font-family: "PingFang SC";
  font-size: 10px;
  margin-left: 5px;
  color: #909090;
}

.tag .card .row3 span {
  font-family: "PingFang SC";
  font-size: 12px;
  margin-left: 35px;
  color: #363636;
}

.tag .card .row3 em {
  font-family: "PingFang SC";
  font-size: 12px;
  margin-left: 5px;
  color: #363636;
}

.reportCalorie {
  margin-top: 10px;
  background: #FFF;
  color: #FF5800;
}

.reportCalorie .title {
  padding-top: 10px;
  padding-bottom: 10px;
  background: #FFF;
}

.fa-square {
  background: #FF5800;
  height: 8px;
  width: 8px;
  float: left;
  margin-left: 15px;
  margin-top: 5px;
  margin-right: 5px;
}

.fa-icon {
  width: 19px;
  height: 19px;
  float: left;
  margin-left: 20px;
  margin-top: 5px;
}

.fa-circle {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: #c40b0b;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .2);
}

.sportPower {
  font-size: 18px;
  margin-bottom: -30px;
}

.chartCalorie {
  text-align: center;
  width: 95%;
  height: 200px;
  margin-top: -35px;
  margin-left: 5%;
}

.classReport {
  background: #F2F2F2;
}

.reportCalorie ul {
  padding-bottom: 100px;
  margin-top: 0px;
}

.classReport li {
  width: 100%;
  margin-top: 1px;
  background: #FFF;
  height: 66px;
  padding-left: 15px;
  float: left;
}

.classReport li .className {
  width: 25%;
  height: 28px;
  line-height: 28px;
  /*background: #EBFFE3;*/
  overflow: hidden;
  font-size: 14px;
  padding: 1px 11px;
  border-radius: 250px;
  float: left;
  margin-top: 20px;
  /*border: solid 1px #37CB00;*/
  text-align: center;
  color: #333;
}

.classReport li .calorieToTal {
  float: left;
  text-align: center;
  display: block;
  width: 25%;
  margin-top: 15px;
}

.calorieToTal em {
  width: 100%;
  overflow: hidden;
  color: black;
  display: block;
  font-size: 16px;
}

.calorieToTal span {
  width: 100%;
  overflow: hidden;
  display: block;
  font-family: "PingFang SC";
  font-weight: normal;
  font-size: 10px;
  color: #C9C9C9;
}

/*pageClass*/
.pageClass {
  text-align: center;
  margin-top: 135px;
  margin-bottom: 40px;
}

.pageClass em {
  text-align: center;
  font-size: 16px;
}

.dayList .dayDetail {
  background: #FFF;
  margin-left: 5%;
  margin-right: 5%;
  height: 165px;
  text-align: center;
  border-radius: 6px;
  margin-top: 15px;
}

.dayDetail .dayTitle {
  font-size: 12px;
  float: right;
  margin-right: 23px;
  margin-top: 16px;
  overflow: hidden;
  display: block;
  font-family: "PingFang SC";
  font-weight: normal;
  color: #9A9A9A;
}

.dayDetail .className {
  width: 85px;
  height: 28px;
  line-height: 25px;
  /*background: #EBFFE3;*/
  overflow: hidden;
  font-size: 14px;
  padding: 1px 11px;
  border-radius: 250px;
  float: left;
  margin-left: 15px;
  margin-top: 13px;
  /*border: solid 1px #37CB00;*/
  text-align: center;
  color: #333;
}

.dayDetail .sportDetail {
  padding-top: 50px;
  text-align: center;
}

.sportDetail ul {
  width: 90%;
  margin-top: 5px;
  margin-left: 5%;
  height: 65px;
  border-bottom: 1px solid #EFEFEF;
}

.sportDetail ul li em i {
  float: left;
  margin-top: 10px;
  margin-left: 10px;
}

.sportDetail li {
  width: 33%;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  text-align: center;
  float: left;
}

.sportDetail li:nth-child(2) {
  border-color: #e2e2e2;
}

.sportDetail li em {
  width: 100%;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  font-size: 22px;
  font-weight: bold;
  text-align: right;
  padding-right: 8px;
}

.sportDetail li span {
  width: 100%;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  color: #c4c4c4;
  font-size: 12px;
  text-align: right;
  padding-right: 8px;
}

.sportDetail .sportDuration {
  text-align: center;
  margin: 0 auto;
  width: 90%;
  height: 40px;
  /*border-bottom: 1px solid #EFEFEF;*/
}

.sportDetail .sportDuration em {
  color: #028FE1;
  margin-left: 5px;
  margin-right: 5px;
}

.sportDetail .sportDuration span {
  font-size: 14px;
  line-height: 40px;
}

.sportDetail .sportView {
  height: 40px;
}

.sportDetail .sportView span {
  float: left;
  margin-left: 5%;
  margin-top: 10px;
  color: #848484;
}

.sportDetail .sportView em {
  float: right;
  font-weight: normal;
  color: #C4C4C4;
  margin-right: 5%;
  margin-top: 10px;
}


.pageDetail {
  text-align: center;
  background: #FFF;
  margin-top: 20px;
  height: 1200px;
}

.pageDetail .btnBack {
  float: left;
  margin-left: 15px;
  margin-top: 14px;
  font-size: 16px;
  overflow: hidden;
  display: block;
  font-family: "PingFang SC";
  color: #ACACAC;
}

.pageDetail .classDetail {
  display: inline-block;
  *display: inline;
  margin-right: 45px;
  zoom: 1;
  margin-top: 30px;
}

.pageDetail .detailTitle {
  float: left;
  background: #EBFFE3;
  border-radius: 12px;
  padding: 1px 15px;
  border: solid 1.5px #37CB00;
}

.detailTitle span {
  display: block;
}

.pageDetail .memberInfo {
  margin-top: 5px;
}

.memberInfo em {
  color: #FFA310;
  font-size: 12px;
}

.memberInfo span {
  font-size: 12px;
  color: #909090;
}

.classInfo {
  background: #FFF;
  text-align: center;
}

.classInfo ul {
  width: 80%;
  margin-left: 10%;
  height: 40px;
  border-bottom: 1px solid #CCC;
  padding-bottom: 50px
}

.classInfo li {
  width: 33%;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  text-align: center;
  float: left;
}

.classInfo li:nth-child(2) {
  border-color: #e2e2e2;
}

.classInfo li em {
  width: 100%;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  font-weight: bold;
  color: #E75296;
  font-size: 14px;
  text-align: center;
}

.classInfo li span {
  width: 100%;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  color: #c4c4c4;
  font-size: 10px;
  text-align: center;
}

/**
动作评分结果选项
 */

.pageScore {
  background: #F2F2F2;
  margin-top: 10px;
}

.pageScore .scoreTitle {
  background: #FFF;
}

.pageScore span {
  height: 50px;
  font-size: 16px;
  font-weight: bold;
  padding-top: 10px;
  text-align: center;
  line-height: 50px;
  font-family: "PingFang SC";
}

.pageScore .scoreContent {
  margin-top: 5px;
  font-weight: normal;
}

.pageScore .scoreContent li {
  width: 100%;
  background: #FFF;
  height: 50px;
  border-bottom: solid 1px #F2F2F2;
}

.pageScore .scoreContent li span {
  font-weight: normal;
}

.pageScore .scoreContent li:first-child span {
  font-weight: bold;
}

.pageScore .scoreContent li .left {
  width: 50%;
  float: left;
  line-height: 30px;
  text-align: right;
  padding-right: 10%;
  display: block;
  border-right: 1px solid #F2F2F2;
}

.pageScore .scoreContent li .right {
  /*background: #EBFFE3;*/
  width: 50%;
  float: left;
  text-align: left;
  padding-left: 10%;
  line-height: 30px;
  border-right: 1px solid #F2F2F2;
  display: block;
}

.pageScore .scoreContent li .right em {
  font-weight: normal;
  margin-right: 20px;
  color: #F2F2F2;
  font-size: 14px;
  float: right;
}

.user {
  width: 100%;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  height: 58px;
  background: #fff;
  padding: 17px 3%;
  margin-bottom: 10px;
}

.user img {
  width: 26.29px;
  height: 26.29px;
  float: left;
  margin-right: 11px;
}

.user span {
  font-family: "PingFang SC";
  font-weight: 500;
  font-size: 18px;
  text-align: left;
  color: #3b3b3b;
}

.user em {
  font-family: "PingFang SC";
  font-weight: normal;
  font-size: 18px;
  text-align: left;
  color: #3b3b3b;
  float: right;
}

.user p {
  font-family: "PingFang SC";
  font-weight: normal;
  font-size: 14px;
  text-align: left;
  color: #ffb43c;
  float: right;
  padding: 0;
  margin: 0;
  margin-left: 3px;
  line-height: 26px;
}

.value {
  width: 100%;
  /*height: 131px;*/
  background: #fff;
  padding: 13px 0;
}

.value .vTitle {
  width: 100%;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  text-align: center;

  font-family: "PingFang SC";
  font-weight: 300;
  font-size: 15px;
}

.vTitle i {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 250px;
  margin-right: 3px;
}

.vTitle.year {
  color: #E75296;
}

.vTitle.year i {
  background: #e75296;
}

.vTitle.price {
  color: #FFB43C;
}

.vTitle.price i {
  background: #FFB43C;
}

.vNum {
  width: 100%;
  /*height: 25px;*/
  overflow: hidden;
  display: block;
  margin: 5px auto;
  margin-bottom: 0;
}

.center {
  width: 100%;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  text-align: center;
}

.vNum em {
  position: relative;
  float: right;
  bottom: 20px;
  font-family: "PingFang SC";
  font-weight: 500;
  font-size: 12px;
  color: #c9c9c9;
  padding-right: 3%;
}

/deep/ .linear-progress {
  width: 90%;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  border-radius: 250px;
}

.limit {
  width: 90%;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  margin-top: 8px;
}

.limit em {
  font-family: "PingFang SC";
  font-weight: 300;
  font-size: 12px;
  text-align: right;
  color: #e75296;
  padding-left: 10px;
}

.limit span {
  font-family: "PingFang SC";
  font-weight: 300;
  font-size: 12px;
  text-align: right;
  color: #909090;
  float: right;
  text-align: right;
  padding-right: 10px;
}

.dataSum {
  width: 100%;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  background: #fff;
  margin-top: 10px;
}

.dataSum .row {
  width: 90%;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  padding: 2% 0;
}

.dataSum .row em {
  float: left;
  font-family: "PingFang SC";
  font-weight: 300;
  font-size: 18px;
  text-align: right;
  color: #3b3b3b;
}

.dataSum .row span {
  font-family: "PingFang SC";
  font-weight: 500;
  font-size: 18px;
  text-align: right;
  color: #3b3b3b;
  float: right;
}

.row .mid {
  width: 66%;
  height: 0px;
  background: transparent;
  border: 1px dotted #e0e0e0;
  float: left;
  margin-top: 11px;
  margin-right: 5px;
  margin-left: 10px;
}

.row .midl {
  width: 54%;
  height: 0px;
}

.login_btn {
  width: 96%;
  height: 50px;
  line-height: 50px;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 30px;
  padding-left: 3%;
  padding-right: 3%;
  background: #E75296;
  color: #fff;
  font-size: 14px;
  text-align: center;
  border: none;
  padding: 0;
  font-size: 24px;
}

/deep/ .mu-warning-text-color {
  float: left;
}

.vNum span {
  width: 100%;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  text-align: center;
}

@media only screen and (max-width: 640px) {

}

@media only screen and (max-width: 480px) {

}

@media only screen and (max-width: 375px) {
  .vNum em {
    padding-right: 0 !important;
  }

  .row .mid {
    width: 49%;
  }

  .row .midl {
    width: 44%;
    height: 0px;
  }
}

@media only screen and (max-width: 360px) {
  .vNum em {
    padding-right: 0 !important;
  }

  .row .mid {
    width: 49%;
  }

  .row .midl {
    width: 44%;
    height: 0px;
  }
}

@media only screen and (max-width: 320px) {

}

@media only screen and (min-width: 641px) {

}
</style>
